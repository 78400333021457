.page-heading {
  font-size: 15px;
  background-color: #f5f5f9;
  padding: 2px 15px 5px 15px;
  font-weight: bold;
  text-decoration: underline;
}

.btn-cust-1 {
  color: #fff;
  background-color: #122932;
  border-color: #122932;
}

.btn-cust-1 {
  &:active
  &:active,
  &:focus{
  color: #fff;
  background-color: #122932;
  border-color: #122932;
  }
}

.btn-cust-1{
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle,
  &:hover{
    color: #fff;
    background-color: #255c72;
    border-color: #255c72;
  }
}

.custom-error {
  width: 100%;
  margin-left: 3px;
  margin-top: 0.25rem;
  font-size: 90%;
  color: #f46a6a;
}

.custom-table-header{
  background-color: #cfefdd;
}
.custom-table-cell{
  border: 1px solid #ababad;
  padding-top: 5px;
  padding-bottom: 5px;
}
.row.no-gutters {
  margin-right: 0;
  margin-left: 0;
  
  & > [class^="col-"],
  & > [class*=" col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}
.nopadd.form-control{
  padding:8px 0px !important;
  //min-height: 30px;
}

.team-name{
  background-color: #fbe6c1;
  padding: 2px 8px;
  font-weight: 500;
  border-radius: 2px;
}

.medal-color-1{
  color: #C9B037;
}
.medal-color-2{
  color: #D7D7D7;
}
.medal-color-3{
  color: #6A3805;
}

.no-y-padding{
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}